<template>
  <el-dialog
    append-to-body
    :before-close="handleClose"
    :title="title"
    :visible.sync="dialogVisible"
    width="40%"
  >
    <Form
      :column="column"
      :label-width="100"
      :query="query"
      @handleImageUrl="handleImageUrl"
    />
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { saveUserGroup, getUserGroup } from '@/api/user'
  import Form from '@/components/Form'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        title: null,
        dialogVisible: false,
        loading: false,
        query: {
          id: null,
          name: null,
          male_title: null,
          male_aid: null,
          male_ico_url: null,
          female_title: null,
          female_aid: null,
          female_ico_url: null,
          creditshigher: null,
          creditslower: null,
          des: null,
          type: null,
        },
      }
    },
    computed: {
      column() {
        return [
          {
            label: '论坛头衔',
            prop: 'name',
            readonly: true,
          },
          {
            label: '男性头衔',
            prop: 'male_title',
          },
          {
            label: '男性图标',
            prop: 'male_aid',
            type: 'uploadImage',
            width: 58,
            height: 28,
            eventName: 'handleImageUrl',
            tips: '高度为28px，宽度可自适应',
          },
          {
            label: '女性头衔',
            prop: 'female_title',
          },
          {
            label: '女性图标',
            prop: 'female_aid',
            type: 'uploadImage',
            width: 58,
            height: 28,
            eventName: 'handleImageUrl',
            tips: '高度为28px，宽度可自适应',
          },
          {
            label: '积分范围',
            type: 'inputGroup',
            placeholder: ['', ''],
            prop: ['creditshigher', 'creditslower'],
            isHide: this.query.type != 'member',
          },
          {
            label: '用户组说明',
            prop: 'des',
            isHide: this.query.type != 'member',
          },
        ]
      },
    },
    methods: {
      handleOpen(title, row) {
        this.title = title
        this.dialogVisible = true
        this.getUserGroup(row)
      },
      async getUserGroup(row) {
        const params = {
          group_id: row.id,
        }
        const res = await getUserGroup(params)
        if (res.success) {
          for (let key of Object.keys(this.query)) {
            if (
              (key == 'male_aid' || key == 'female_aid') &&
              res.data[key] == 0
            ) {
              this.query[key] =
                key == 'male_aid'
                  ? res.data.male_ico_url
                  : res.data.female_ico_url
            } else {
              this.query[key] = res.data[key]
            }
          }
        }
      },
      handleClose(done) {
        done()
        this.cancel()
      },
      // 提交
      async onSubmit() {
        if (this.loading) return
        this.loading = true
        const params = this.query
        const res = await saveUserGroup(params)
        this.loading = false
        if (res.success) {
          this.cancel()
          this.$emit('getTableData')
          this.$message.success(res.msg)
        }
      },
      // 取消
      cancel() {
        this.dialogVisible = false
        setTimeout(() => {
          for (let key in this.query) {
            this.query[key] = null
          }
        }, 500)
      },
      handleImageUrl(prop, id) {
        this.query[prop] = id
      },
    },
  }
</script>

<style lang="scss" scoped></style>
