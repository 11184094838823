const config = {
  loading1: false,
  loading2: false,
  loading3: false,
  member: [],
  special: [],
  system: [],
  // 表格配置
  memberList: Object.freeze([
    { label: '论坛头衔', prop: 'name' },
    { label: '男性头衔', slotName: 'male_title' },
    { label: '图标', slotName: 'male_ico_url' },
    { label: '女性头衔', slotName: 'female_title' },
    { label: '图标', slotName: 'female_ico_url' },
    { label: '积分范围', slotName: 'credit' },
    { label: '用户组说明', slotName: 'des' },
    {
      label: '操作',
      isButton: true,
      btnList: [
        {
          label: '配置',
          type: 'primary',
          eventName: 'handleConfig',
        },
      ],
    },
  ]),
  // 表格配置
  systemList: Object.freeze([
    { label: '论坛头衔', prop: 'name' },
    { label: '男性头衔', slotName: 'male_title' },
    { label: '图标', slotName: 'male_ico_url' },
    { label: '女性头衔', slotName: 'female_title' },
    { label: '图标', slotName: 'female_ico_url' },
    {
      label: '操作',
      isButton: true,
      btnList: [
        {
          label: '配置',
          type: 'primary',
          eventName: 'handleConfig',
        },
      ],
    },
  ]),
  // 表格配置
  specialList: Object.freeze([
    { label: '论坛头衔', prop: 'name' },
    { label: '男性头衔', slotName: 'male_title' },
    { label: '图标', slotName: 'male_ico_url' },
    { label: '女性头衔', slotName: 'female_title' },
    { label: '图标', slotName: 'female_ico_url' },
    {
      label: '操作',
      isButton: true,
      btnList: [
        {
          label: '配置',
          type: 'primary',
          eventName: 'handleConfig',
        },
      ],
    },
  ]),
}

export default config
