var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "personal-group-container" },
    [
      _c("div", { staticClass: "personal-group-info" }, [
        _vm._v(
          " 对接论坛的基础配置，用户组管理请到pc端论坛管理中心配置。用户组图标建议尺寸为58x28px，宽度可以自适应。 "
        ),
      ]),
      _c("div", { staticClass: "personal-group-title" }, [
        _vm._v("会员用户组"),
      ]),
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.memberList,
          "is-pagination": false,
          loading: _vm.loading1,
          "max-height": "100%",
          "table-data": _vm.member,
        },
        on: {
          handleConfig: _vm.handleConfig,
          handleCurrentChange: _vm.handleCurrentChange,
        },
        scopedSlots: _vm._u([
          {
            key: "male_title",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("el-input", {
                  attrs: { placeholder: "请输入男性头衔" },
                  model: {
                    value: row.male_title,
                    callback: function ($$v) {
                      _vm.$set(row, "male_title", $$v)
                    },
                    expression: "row.male_title",
                  },
                }),
              ]
            },
          },
          {
            key: "male_ico_url",
            fn: function (ref) {
              var row = ref.row
              var index = ref.index
              return [
                _c("UploadImage", {
                  attrs: {
                    clean: false,
                    "event-name": "handleImageUrl",
                    height: "28",
                    "image-url": row.male_ico_url,
                    index: index,
                    limit: 1,
                    "list-name": "member",
                    prop: "male_ico_url",
                    width: "58",
                  },
                  on: { handleImageUrl: _vm.handleImageUrl },
                }),
              ]
            },
          },
          {
            key: "female_title",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("el-input", {
                  attrs: { placeholder: "请输入女性头衔" },
                  model: {
                    value: row.female_title,
                    callback: function ($$v) {
                      _vm.$set(row, "female_title", $$v)
                    },
                    expression: "row.female_title",
                  },
                }),
              ]
            },
          },
          {
            key: "female_ico_url",
            fn: function (ref) {
              var row = ref.row
              var index = ref.index
              return [
                _c("UploadImage", {
                  attrs: {
                    clean: false,
                    "event-name": "handleImageUrl",
                    height: "28",
                    "image-url": row.female_ico_url,
                    index: index,
                    limit: 1,
                    "list-name": "member",
                    prop: "female_ico_url",
                    width: "58",
                  },
                  on: { handleImageUrl: _vm.handleImageUrl },
                }),
              ]
            },
          },
          {
            key: "credit",
            fn: function (ref) {
              var row = ref.row
              return [
                _vm._v(
                  " " +
                    _vm._s(row.creditshigher) +
                    " ~ " +
                    _vm._s(row.creditslower) +
                    " "
                ),
              ]
            },
          },
          {
            key: "des",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("el-input", {
                  attrs: { placeholder: "请输入用户组说明" },
                  model: {
                    value: row.des,
                    callback: function ($$v) {
                      _vm.$set(row, "des", $$v)
                    },
                    expression: "row.des",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-button",
        {
          staticClass: "personal-group-btn",
          attrs: { loading: _vm.loading1, type: "primary" },
          on: {
            click: function ($event) {
              return _vm.onSave(1)
            },
          },
        },
        [_vm._v(" 保 存 ")]
      ),
      _c("div", { staticClass: "personal-group-title" }, [
        _vm._v("系统用户组"),
      ]),
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.systemList,
          "is-pagination": false,
          loading: _vm.loading2,
          "max-height": "100%",
          "table-data": _vm.system,
        },
        on: {
          handleConfig: _vm.handleConfig,
          handleCurrentChange: _vm.handleCurrentChange,
        },
        scopedSlots: _vm._u([
          {
            key: "male_title",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("el-input", {
                  attrs: { placeholder: "请输入男性头衔" },
                  model: {
                    value: row.male_title,
                    callback: function ($$v) {
                      _vm.$set(row, "male_title", $$v)
                    },
                    expression: "row.male_title",
                  },
                }),
              ]
            },
          },
          {
            key: "male_ico_url",
            fn: function (ref) {
              var row = ref.row
              var index = ref.index
              return [
                _c("UploadImage", {
                  attrs: {
                    clean: false,
                    "event-name": "handleImageUrl",
                    height: "28",
                    "image-url": row.male_ico_url,
                    index: index,
                    limit: 1,
                    "list-name": "system",
                    prop: "male_ico_url",
                    width: "58",
                  },
                  on: { handleImageUrl: _vm.handleImageUrl },
                }),
              ]
            },
          },
          {
            key: "female_title",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("el-input", {
                  attrs: { placeholder: "请输入女性头衔" },
                  model: {
                    value: row.female_title,
                    callback: function ($$v) {
                      _vm.$set(row, "female_title", $$v)
                    },
                    expression: "row.female_title",
                  },
                }),
              ]
            },
          },
          {
            key: "female_ico_url",
            fn: function (ref) {
              var row = ref.row
              var index = ref.index
              return [
                _c("UploadImage", {
                  attrs: {
                    clean: false,
                    "event-name": "handleImageUrl",
                    height: "28",
                    "image-url": row.female_ico_url,
                    index: index,
                    limit: 1,
                    "list-name": "system",
                    prop: "female_ico_url",
                    width: "58",
                  },
                  on: { handleImageUrl: _vm.handleImageUrl },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-button",
        {
          staticClass: "personal-group-btn",
          attrs: { loading: _vm.loading2, type: "primary" },
          on: {
            click: function ($event) {
              return _vm.onSave(2)
            },
          },
        },
        [_vm._v(" 保 存 ")]
      ),
      _c("div", { staticClass: "personal-group-title" }, [
        _vm._v("自定义管理组"),
      ]),
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.specialList,
          "is-pagination": false,
          loading: _vm.loading3,
          "max-height": "100%",
          "table-data": _vm.special,
        },
        on: {
          handleConfig: _vm.handleConfig,
          handleCurrentChange: _vm.handleCurrentChange,
        },
        scopedSlots: _vm._u([
          {
            key: "male_title",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("el-input", {
                  attrs: { placeholder: "请输入男性头衔" },
                  model: {
                    value: row.male_title,
                    callback: function ($$v) {
                      _vm.$set(row, "male_title", $$v)
                    },
                    expression: "row.male_title",
                  },
                }),
              ]
            },
          },
          {
            key: "male_ico_url",
            fn: function (ref) {
              var row = ref.row
              var index = ref.index
              return [
                _c("UploadImage", {
                  attrs: {
                    clean: false,
                    "event-name": "handleImageUrl",
                    height: "28",
                    "image-url": row.male_ico_url,
                    index: index,
                    limit: 1,
                    "list-name": "special",
                    prop: "male_ico_url",
                    width: "58",
                  },
                  on: { handleImageUrl: _vm.handleImageUrl },
                }),
              ]
            },
          },
          {
            key: "female_title",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("el-input", {
                  attrs: { placeholder: "请输入女性头衔" },
                  model: {
                    value: row.female_title,
                    callback: function ($$v) {
                      _vm.$set(row, "female_title", $$v)
                    },
                    expression: "row.female_title",
                  },
                }),
              ]
            },
          },
          {
            key: "female_ico_url",
            fn: function (ref) {
              var row = ref.row
              var index = ref.index
              return [
                _c("UploadImage", {
                  attrs: {
                    clean: false,
                    "event-name": "handleImageUrl",
                    height: "28",
                    "image-url": row.female_ico_url,
                    index: index,
                    limit: 1,
                    "list-name": "special",
                    prop: "female_ico_url",
                    width: "58",
                  },
                  on: { handleImageUrl: _vm.handleImageUrl },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-button",
        {
          staticClass: "personal-group-btn",
          attrs: { loading: _vm.loading3, type: "primary" },
          on: {
            click: function ($event) {
              return _vm.onSave(3)
            },
          },
        },
        [_vm._v(" 保 存 ")]
      ),
      _c("ConfigDialog", {
        ref: "configDialog",
        on: { getTableData: _vm.getGroupListByType },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }