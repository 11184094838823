<template>
  <div class="personal-group-container">
    <div class="personal-group-info">
      对接论坛的基础配置，用户组管理请到pc端论坛管理中心配置。用户组图标建议尺寸为58x28px，宽度可以自适应。
    </div>
    <div class="personal-group-title">会员用户组</div>
    <!-- 表格 -->
    <TablePaging
      :column-list="memberList"
      :is-pagination="false"
      :loading="loading1"
      max-height="100%"
      :table-data="member"
      @handleConfig="handleConfig"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 男性头衔 -->
      <template #male_title="{ row }">
        <el-input v-model="row.male_title" placeholder="请输入男性头衔" />
      </template>
      <!-- 图标 -->
      <template #male_ico_url="{ row, index }">
        <UploadImage
          :clean="false"
          event-name="handleImageUrl"
          height="28"
          :image-url="row.male_ico_url"
          :index="index"
          :limit="1"
          list-name="member"
          prop="male_ico_url"
          width="58"
          @handleImageUrl="handleImageUrl"
        />
      </template>
      <!-- 女性头衔 -->
      <template #female_title="{ row }">
        <el-input v-model="row.female_title" placeholder="请输入女性头衔" />
      </template>
      <!-- 图标 -->
      <template #female_ico_url="{ row, index }">
        <UploadImage
          :clean="false"
          event-name="handleImageUrl"
          height="28"
          :image-url="row.female_ico_url"
          :index="index"
          :limit="1"
          list-name="member"
          prop="female_ico_url"
          width="58"
          @handleImageUrl="handleImageUrl"
        />
      </template>
      <!-- 积分范围 -->
      <template #credit="{ row }">
        {{ row.creditshigher }} ~ {{ row.creditslower }}
      </template>
      <!-- 用户组说明 -->
      <template #des="{ row }">
        <el-input v-model="row.des" placeholder="请输入用户组说明" />
      </template>
    </TablePaging>
    <el-button
      class="personal-group-btn"
      :loading="loading1"
      type="primary"
      @click="onSave(1)"
    >
      保 存
    </el-button>

    <div class="personal-group-title">系统用户组</div>
    <!-- 表格 -->
    <TablePaging
      :column-list="systemList"
      :is-pagination="false"
      :loading="loading2"
      max-height="100%"
      :table-data="system"
      @handleConfig="handleConfig"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 男性头衔 -->
      <template #male_title="{ row }">
        <el-input v-model="row.male_title" placeholder="请输入男性头衔" />
      </template>
      <!-- 图标 -->
      <template #male_ico_url="{ row, index }">
        <UploadImage
          :clean="false"
          event-name="handleImageUrl"
          height="28"
          :image-url="row.male_ico_url"
          :index="index"
          :limit="1"
          list-name="system"
          prop="male_ico_url"
          width="58"
          @handleImageUrl="handleImageUrl"
        />
      </template>
      <!-- 女性头衔 -->
      <template #female_title="{ row }">
        <el-input v-model="row.female_title" placeholder="请输入女性头衔" />
      </template>
      <!-- 图标 -->
      <template #female_ico_url="{ row, index }">
        <UploadImage
          :clean="false"
          event-name="handleImageUrl"
          height="28"
          :image-url="row.female_ico_url"
          :index="index"
          :limit="1"
          list-name="system"
          prop="female_ico_url"
          width="58"
          @handleImageUrl="handleImageUrl"
        />
      </template>
    </TablePaging>
    <el-button
      class="personal-group-btn"
      :loading="loading2"
      type="primary"
      @click="onSave(2)"
    >
      保 存
    </el-button>

    <div class="personal-group-title">自定义管理组</div>
    <!-- 表格 -->
    <TablePaging
      :column-list="specialList"
      :is-pagination="false"
      :loading="loading3"
      max-height="100%"
      :table-data="special"
      @handleConfig="handleConfig"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 男性头衔 -->
      <template #male_title="{ row }">
        <el-input v-model="row.male_title" placeholder="请输入男性头衔" />
      </template>
      <!-- 图标 -->
      <template #male_ico_url="{ row, index }">
        <UploadImage
          :clean="false"
          event-name="handleImageUrl"
          height="28"
          :image-url="row.male_ico_url"
          :index="index"
          :limit="1"
          list-name="special"
          prop="male_ico_url"
          width="58"
          @handleImageUrl="handleImageUrl"
        />
      </template>
      <!-- 女性头衔 -->
      <template #female_title="{ row }">
        <el-input v-model="row.female_title" placeholder="请输入女性头衔" />
      </template>
      <!-- 图标 -->
      <template #female_ico_url="{ row, index }">
        <UploadImage
          :clean="false"
          event-name="handleImageUrl"
          height="28"
          :image-url="row.female_ico_url"
          :index="index"
          :limit="1"
          list-name="special"
          prop="female_ico_url"
          width="58"
          @handleImageUrl="handleImageUrl"
        />
      </template>
    </TablePaging>
    <el-button
      class="personal-group-btn"
      :loading="loading3"
      type="primary"
      @click="onSave(3)"
    >
      保 存
    </el-button>
    <ConfigDialog ref="configDialog" @getTableData="getGroupListByType" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import UploadImage from '@/components/upload/image.vue'
  import ConfigDialog from './components/configDialog.vue'
  import { getGroupListByType, groupsSave } from '@/api/user'
  import config from './config'
  export default {
    components: {
      TablePaging,
      UploadImage,
      ConfigDialog,
    },
    mixins: [tableMixins],
    data() {
      return config
    },
    computed: {
      ...mapGetters({
        qiniuToken: 'user/qiniuToken',
      }),
    },
    created() {
      if (!this.qiniuToken) this.$store.dispatch('user/getQNToken')
      this.getGroupListByType()
    },
    methods: {
      async getGroupListByType() {
        const res = await getGroupListByType()
        if (res.success) {
          this.member = res.data.member
          this.special = res.data.special
          this.system = res.data.system
        }
      },
      handleConfig(val) {
        this.$refs.configDialog.handleOpen('系统用户组配置', val)
      },
      async onSave(num) {
        const listName = ['member', 'system', 'special']
        const params = {
          groups: this[listName[num - 1]],
        }
        this[`loading${num}`] = true
        const res = await groupsSave(params)
        this[`loading${num}`] = false
        if (res.success) {
          this.$message.success('保存成功')
        } else {
          this.$message.error('保存失败')
        }
      },
      handleImageUrl(eventName, prop, id, listName, index) {
        this[listName][index][prop] = id
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.personal-group';
  #{$base}-container {
    #{$base}-title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    #{$base}-info {
      font-size: 13px;
      color: $base-color-text-secondary;
      margin-bottom: 20px;
      span {
        color: $base-color-red;
      }
    }
    #{$base}-btn {
      margin: 20px 0;
    }
    ::v-deep .table {
      .image-uploader {
        margin: 0 auto;
      }
    }
  }
</style>
